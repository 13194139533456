import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUserData: [],
  },
  getters: {
    get_user_info: (state) => {
      return state.currentUserData;
    },
  },
  mutations: {
    SET_USER_INFO(state, val) {
      state.currentUserData = val;
    },
  },
  plugins: [createPersistedState()],
  actions: {},
  modules: {},
});
