import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/loginpage",
    name: "loginPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/MainPages/LoginPage.vue"),
  },
  {
    path: "/landingpage",
    name: "landingpage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/MainPages/LandingPage.vue"
      ),
      children: [
        {
          path: "/admins",
          name: "Admins",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/views/ChildPages/AdminPage.vue"),
        },
        {
          path: "/coaches",
          name: "Coaches",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/views/ChildPages/CoachPage.vue"),
        },

      ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
